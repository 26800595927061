import React from "react";
import Header from "../header";

function Page({ children }) {
  return (
    <>
      <Header key="header" />
      <main id="main-content" key="main">
        {children}
      </main>
    </>
  );
}

export default Page;
