import React from "react";
import logo from "./images/logo_full_520x.png";
import "./css/index.css";

function Header() {
  return (
    <nav className="navbar is-primary is-fixed-bottom has-navbar-fixed-bottom" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={logo} alt="www.WhatMakesYouHappy.faith" height="40" />
        </a>
      </div>
    </nav>
  );
}

export default Header;
