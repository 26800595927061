import React from 'react';
import Home from '../home';

import './style/index.sass';

function App() {
  return (
    <Home />
  );
}

export default App;
