import React from "react";
import Page from "../page";

import "./css/index.css";

const handleClick = () => {
  if (window.gtag) {
    window.gtag('event', 'submit', {
      'event_label': 'SubmitWhatMakesYouHappySurvey',
      'event_category': 'survey',
      'value': 0,
      'non_interaction': false
    });
  }
  alert("Thanks for answering it. Please come back few days later to check this site with more content.")
}

function Home() {
  return (
    <Page>
      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">What Makes You Happy?</h1>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="card">
          <div className="card-content columns">
            <div className="field column is-vcentered">
              <div className="control">
                <div className="select is-info is-rounded is-large ">
                  <select>
                    <option>Please select your answer</option>
                    <option>Money</option>
                    <option>Promotion</option>
                    <option>Freedom</option>
                    <option>Power</option>
                    <option>Big house</option>
                    <option>High paid job</option>
                    <option>Solving problem</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="field is-grouped column is-vcentered">
              <div className="control">
                <button className="button is-link is-medium" onClick={handleClick}>Verify your answer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Home;
